import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CarrierContent from "../components/carrier/carrier-content"

const desc =
  "Carriers can use the digital freight market place to book their loads, get paid within 48 hours of submitted the POD, and bundle loads to maximize miles."
const Carrier = () => (
  <Layout>
    <SEO title="Carrier" description={desc} />
    <CarrierContent className="container mx-auto flex flex-wrap justify-center" />
  </Layout>
)

export default Carrier
