import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Button from "../shared/button"
import Image from "../shared/image"

const Carrier = styled.section`
  margin: 0 auto;
  maxwidth: 100%;
  padding: 0px 1.0875rem 1.45rem;
`

const Book = styled.div`
  @media screen and (min-device-maxwidth: 1600px) {
    margin-top: 100px;
  }
`

const Paid = styled.div`
  @media screen and (min-device-maxwidth: 1600px) {
    margin-top: 100px;
  }
`

const Bundle = styled.div`
  @media screen and (min-device-maxwidth: 1600px) {
    margin-top: 100px;
  }
`

const CarrierContent = ({ className }) => {
  const data = useStaticQuery(graphql`
    query CarrierContentImageQuery {
      book: file(relativePath: { eq: "book.png" }) {
        childImageSharp {
          fluid(maxWidth: 1507, maxHeight: 1494) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      getpaid: file(relativePath: { eq: "get-paid.png" }) {
        childImageSharp {
          fluid(maxWidth: 1544, maxHeight: 1408) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bundle: file(relativePath: { eq: "bundle.png" }) {
        childImageSharp {
          fluid(maxWidth: 1351, maxHeight: 1491) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      google: file(relativePath: { eq: "google-play.png" }) {
        childImageSharp {
          fluid(maxWidth: 135, maxHeight: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      apple: file(relativePath: { eq: "apple-store.png" }) {
        childImageSharp {
          fluid(maxWidth: 135, maxHeight: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Carrier className={className}>
      <div className="w-full text-center mb-20">
        <h1 className="text-gray-800 font-bold text-2xl lg:text-4xl my-4">
          Never Drive on Empty Again
        </h1>
        <h3 className="font-semibold text-xl lg:text-2xl mb-8 text-gray-800 pb-5">
          There’s nothing worse than driving empty miles. <br /> Maximize your
          miles and increase total dollars per mile.
        </h3>
        <div className="inline-flex flex-wrap">
          <div className="w-full">
            <a
              href="https://apps.apple.com/jm/app/archerhub/id1466242683"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button
                className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 lg:py-4 px-4 lg:px-8 inline-flex items-center rounded"
                type="button"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                Download Archerhub App
              </Button>
            </a>
          </div>
          <div className="w-full">
            <div className="flex items-baseline justify-center">
              <a
                className="mr-2"
                href="https://play.google.com/store/apps/details?id=com.archeratlantic.archerdriverapp"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  fluid={data.google.childImageSharp.fluid}
                  title="Google Play Store"
                  width="135px"
                  mobileWidth="90px"
                  tabletWidth="120px"
                />
              </a>
              <a
                href="https://apps.apple.com/jm/app/archerhub/id1466242683"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  fluid={data.apple.childImageSharp.fluid}
                  width="135px"
                  mobileWidth="90px"
                  tabletWidth="120px"
                  title="Apple Store"
                  className="mt-3"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Book className="w-full">
        <div className="flex flex-wrap lg:flex-no-wrap items-center">
          <div className="w-full flex justify-center lg:w-auto lg:flex-auto">
            <Image
              width="350px"
              mobileWidth="200px"
              tabletWidth="200px"
              fluid={data.book.childImageSharp.fluid}
              alt="Carriers book their loads online any time."
            />
          </div>
          <div className="flex-auto my-5 text-center lg:text-left">
            <h3 className="font-bold text-blue-500 text-xl lg:text-3xl mb-2">
              Book
            </h3>
            <p className="text-gray-700 text-lg lg:text-xl font-semibold leading-normal">
              Book your loads anytime. No phones, no emails, just one click and
              the load is yours.
            </p>
          </div>
        </div>
      </Book>

      <Paid className="w-full">
        <div className="flex flex-wrap lg:flex-no-wrap items-center">
          <div className="w-full flex justify-center lg:w-auto lg:hidden">
            <Image
              width="400px"
              mobileWidth="200px"
              tabletWidth="200px"
              fluid={data.getpaid.childImageSharp.fluid}
              alt="Carriers get paid within 48 hours of submitted POD."
            />
          </div>
          <div className="flex-auto my-5 text-center lg:text-left">
            <div className="font-bold text-blue-500 text-xl lg:text-3xl mb-2">
              Get Paid
            </div>
            <p className="text-gray-700 text-lg lg:text-xl font-semibold leading-normal">
              Get paid. Fast. Our quick pay program delivers payment within 48
              hours of submitted POD. Complete your loads with just one click.
            </p>
          </div>
          <div className="hidden lg:block lg:flex-auto">
            <Image
              width="400px"
              mobileWidth="200px"
              tabletWidth="200px"
              fluid={data.getpaid.childImageSharp.fluid}
              alt="Get paid. Fast. Archerhub quick pay program delivers payment within 48 hours of submitted POD. Complete your loads with just one click."
            />
          </div>
        </div>
      </Paid>
      <Bundle className="w-full">
        <div className="flex flex-wrap lg:flex-no-wrap items-center">
          <div className="w-full flex justify-center lg:w-auto lg:flex-auto">
            <Image
              width="400px"
              mobileWidth="200px"
              tabletWidth="200px"
              fluid={data.bundle.childImageSharp.fluid}
              alt="Archer hub allows carriers to bundle loads."
            />
          </div>
          <div className="flex-auto my-5 text-center lg:text-left">
            <div className="font-bold text-blue-500  text-xl lg:text-3xl mb-2">
              Bundle
            </div>
            <p className="text-gray-700 text-lg lg:text-xl font-semibold leading-normal">
              Bundle your loads. You can bundle your loads a week out.
              Artificial intelligence tools learn your preferences and suggest
              how to tie each load to maximize dollars and reduce empty miles.
            </p>
          </div>
        </div>
      </Bundle>
      <div className="inline-flex flex-wrap text-center mb-5">
        <div className="w-full">
          <a
            href="https://apps.apple.com/jm/app/archerhub/id1466242683"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button
              className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 lg:py-4 px-4 lg:px-8 inline-flex items-center rounded"
              type="button"
            >
              <svg
                className="fill-current w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
              </svg>
              Download Archerhub App
            </Button>
          </a>
        </div>
        <div className="w-full">
          <div className="flex items-baseline justify-center">
            <a
              className="mr-2"
              href="https://play.google.com/store/apps/details?id=com.archeratlantic.archerdriverapp"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image
                fluid={data.google.childImageSharp.fluid}
                title="Google Play Store"
                width="135px"
                mobileWidth="90px"
                tabletWidth="120px"
              />
            </a>
            <a
              href="https://apps.apple.com/jm/app/archerhub/id1466242683"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image
                fluid={data.apple.childImageSharp.fluid}
                width="135px"
                mobileWidth="90px"
                tabletWidth="120px"
                title="Apple Store"
                className="mt-3"
              />
            </a>
          </div>
        </div>
      </div>
    </Carrier>
  )
}

CarrierContent.propTypes = {
  className: PropTypes.string,
}

CarrierContent.defaultProps = {
  className: null,
}

export default CarrierContent
